import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { positionRight } from '@reach/popover'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
  MenuLink,
} from '@reach/menu-button'

import { useUserbaseUser } from '../services/userbase'

import FolksyLogo from '../images/svg/folksy-logo.inline.svg'
import UserIcon from '../images/svg/user-icon.inline.svg'
import UserFilledIcon from '../images/svg/user-filled-icon.inline.svg'
import { useUserbaseUserState } from '../services/userbase/userProvider'

const Header = ({ siteTitle }) => {
  const { user } = useUserbaseUserState()
  const { signOut } = useUserbaseUser()

  return (
    <header className="fixed top-0 w-full h-header z-top antialiased bg-contextual shadow px-12 sm:px-24 pt-20 pb-16">
      <a
        className="group fixed top-0 left-0 block text-contextual font-semibold"
        href="#main-content"
      >
        <span className="sr-only group-focus:sr-undo">
          Skip to main content
        </span>
      </a>
      <div className="flex items-center justify-between max-w-container mx-auto">
        <Link className="block text-contextual" to="/">
          <FolksyLogo /> <span className="sr-only">{siteTitle}</span>
        </Link>

        <p className="hd-xs text-contextual uppercase sm-d:hidden">
          Get to know folks
        </p>

        {user ? (
          <Menu>
            <MenuButton className="flex items-center util-sm text-contextual">
              {user.username}
              <UserFilledIcon aria-hidden="true" className="ml-12" />
            </MenuButton>
            <MenuPopover className="pt-20" position={positionRight}>
              <MenuItems>
                <MenuLink as={Link} to="/account/edit">
                  Change Password
                </MenuLink>
                <MenuItem onSelect={signOut}>Log Out</MenuItem>
              </MenuItems>
            </MenuPopover>
          </Menu>
        ) : (
          <div>
            <Link
              className="flex items-center util-sm text-white no-underline"
              to="/login"
            >
              Sign In <UserIcon className="ml-12" />
            </Link>
            {/* @TODO where does register GO? <Link to="/register">Register</Link> */}
          </div>
        )}
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
