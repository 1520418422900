/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { node, string } from 'prop-types'
import cx from 'classnames'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import './before.css'
import './layout.css'
import './after.css'

const Layout = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="flex flex-col">
      <Header siteTitle={data.site.siteMetadata.title} />
      <main
        id="main-content"
        className={cx(className, 'py-40 sm:py-60 px-12 sm:px-24')}
      >
        {children}
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: node.isRequired,
  className: string,
}

export default Layout
